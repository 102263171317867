<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Driver Payout Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Driver Payout Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Driver Payout Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-container>
      <div v-if="showPayout">
        <v-card>
          <v-container>
            <v-row>
              <v-col cols="4" md="4">
                <date-picker
                  v-model="payout_date"
                  type="datetime"
                  value-type="DD-MM-YYYY hh:mm:ss A"
                ></date-picker>
              </v-col>
              <v-col cols="4" md="4">
                <v-text-field
                  class="purple-input"
                  label="Payout Amount"
                  v-model="payout_amount"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  type="button"
                  color="primary"
                  class="mr-0"
                  @click="submit"
                  :loading="loading"
                >
                  {{
                    onlyEarningPayout == true
                      ? "Back"
                      : editEarningPayout == true
                      ? "Update"
                      : "Submit"
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-container>
    <v-card-actions>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            width="160px"
            outlined
            text
            type="button"
            color="primary"
            class="mr-0"
            @click="addPayout"
            ><v-icon>mdi-plus </v-icon>Add Driver Payout</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card>
      <v-card-title>Add Driver Payout</v-card-title>
      <v-container>
        <v-data-table
          :headers="columnsEarningPayout"
          :items="driverEarningPayout"
          hide-default-footer
          disable-pagination
          class="elevation-1"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.payout_date`]="{ item }">
            {{ format_date(item.payout_date) }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col>
                  <v-text-field
                    @keyup="globalSearch"
                    v-model="search"
                    label="Search"
                    class="mx-4 mt-4"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete record?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogDelete = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item._id`]="{ index }">
            {{ numbers[index] }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              v-if="modulePermission.Update"
              @click="editDriverEarningPayout(item._id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="modulePermission.Delete"
              @click="deleteEarningPayout(item._id)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
        <div class="custom_pagination_design">
          <span>
            Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
            <b>{{ totalRecord }}</b> Records
          </span>
          <v-pagination
            class="float-right"
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </div>
      </v-container>
    </v-card>
    <v-card>
      <v-card-title>Driver Lager Statment</v-card-title>
      <v-container>
        <v-data-table
          :headers="columnsOrders"
          :items="driverOrderList"
          hide-default-footer
          disable-pagination
          class="elevation-1"
        >
        </v-data-table>
      </v-container>
    </v-card>
    <v-row class="mt-3">
      <v-col cols="12" class="text-left">
        <router-link :to="{ name: 'All Drivers Earning Payout' }">
          <v-btn type="button" color="primary" class="mr-0">Back</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  props: ["id", "action"],
  components: { DatePicker },
  data() {
    return {
      moduleName: "DriverPayout",
      modulePermission: [],
      driverOrderList: [],
      driverEarningPayout: [],
      showPayout: false,
      menu: false,
      driverEarningPayoutId: "",
      payout_date: null,
      payout_amount: "",
      loading: false,
      editEarningPayout: false,
      onlyEarningPayout: false,
      search: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      numbers: [],
      dialogDelete: false,
      deleteRecord: "",
      addFlash: false,
      editFlash: false,
      deleteFlash: false,
    };
  },
  computed: {
    columnsOrders() {
      return [
        { sortable: false, text: "Order Date", value: "date", sortable: false },
        {
          sortable: false,
          text: "Order Id",
          value: "order_id",
          sortable: false,
        },
        {
          text: "Delivery Charges",
          value: "delivery_charges",
          sortable: false,
        },
        {
          text: "Payout",
          value: "payout_amount",
          sortable: false,
        },
      ];
    },
    columnsEarningPayout() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Id", value: "_id", sortable: false },
        { sortable: false, text: "Payout Date", value: "payout_date" },
        { sortable: false, text: "Payout Amount", value: "payout_amount" },
      ];
    },
  },
  methods: {
    getDriverOrderList(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "driverListOrders/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.driverOrderList = response.data.lager;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDriverEarningPayout(id) {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "driverEarningPayout/getData/" +
            id +
            "/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.driverEarningPayout = response.data.driverEarningPayout.data;
            this.loading = false;
            this.totalPages = response.data.driverEarningPayout.last_page;
            this.startRecord = response.data.driverEarningPayout.from;
            this.endRecord = response.data.driverEarningPayout.to;
            this.totalRecord = response.data.driverEarningPayout.total;
            this.numbers = [];
            for (
              let num = response.data.driverEarningPayout.from;
              num <= response.data.driverEarningPayout.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editDriverEarningPayout(id) {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "driverEarningPayout/edit/" + id
        )
        .then((response) => {
          if (response.status == 200) {
            this.showPayout = true;
            this.editEarningPayout = true;
            this.driverEarningPayoutId = response.data.driverEarningPayout._id;
            this.payout_date =
              response.data.driverEarningPayout.payout_date_normal;
            this.payout_amount =
              response.data.driverEarningPayout.payout_amount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteEarningPayout(id) {
      this.dialogDelete = true;
      this.deleteRecord = id;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "driverEarningPayout/delete/" +
            this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getDriverEarningPayout(this.id);
            this.getDriverOrderList(this.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getDriverEarningPayout();
    },
    format_date(value) {
      if (value) {
        var res = new Date(value * 1000);
        return moment(res).format("DD/MM/YYYY hh:mm:ss A");
      }
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getDriverEarningPayout(this.id);
    },
    addPayout() {
      this.showPayout = true;
      this.editEarningPayout = false;
    },
    submit() {
      // this.loading = true;
      let data = {
        id: this.driverEarningPayoutId,
        driver_id: this.id,
        payout_date: this.payout_date,
        payout_amount: this.payout_amount,
      };
      if (this.editEarningPayout == true) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "driverEarningPayout/update",
            data
          )
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.editFlash = true;
              this.payout_date = "";
              this.payout_amount = "";
              this.getDriverEarningPayout(this.id);
              this.getDriverOrderList(this.id);
              this.showPayout = false;
              // this.$router.go()
              // this.$router.push({
              //   name: "View Driver Earning Payout",
              //   params: { id: this.id },
              // });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + "driverEarningPayout/create",
            data
          )
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.addFlash = true;
              this.payout_date = "";
              this.payout_amount = "";
              this.getDriverEarningPayout(this.id);
              this.getDriverOrderList(this.id);
              this.showPayout = false;
              // this.$router.go()
              // this.$router.push({
              //   name: "View Driver Earning Payout",
              //   params: { id: this.id },
              // });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getDriverOrderList(this.id);
      this.getDriverEarningPayout(this.id);
      let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
      this.modulePermission = permissions[this.moduleName];
    }
  },
};
</script>
<style scoped>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: none;
}
</style>
